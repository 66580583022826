import * as React from "react";

import Layout from "../components/Layout";
import SupportBanner from "../components/SupportBanner";
import SupportSection from "../components/SupportSection";

export default class FaqPage extends React.Component {
  render() {
    return (
      <Layout
        noPadding
        isWhite={true}
        darkLogo={true}
        isDisplayGetQuoteBtn={false}
      >
        <SupportBanner />

        <section className="section">
          <div className="container">
            <div className="content">
              <SupportSection />
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
