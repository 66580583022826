import React from "react";
import { Link, withPrefix } from "gatsby";

const data = [
  {
    title: "Financial Hardship",
    link: "/static/financial-hardship/",
    id: "financial",
    image: "/img/financial.png",
  },
  {
    title: "Complaints",
    link: "/static/complaints/",
    id: "complaints",
    image: "/img/complaint.jpg",
  },
  {
    title: "Vulnerable customers policy",
    link: "/static/vulnerable-customers-policy/",
    id: "policy",
    image: "/img/policy.png",
  },
];

const SupportSection = ({ hasBottomRadius = false }) => {
  return (
    <div
      className={`nu-icons-container ${hasBottomRadius ? "bottom-radius" : ""}`}
    >
      <div className="content nu-c">
        <div className="nu-title">
          <span>
            If you have any questions or need assistance, we're here to provide
            the support you need.
          </span>

          <Link to="/forms/contact-us/" className="nu-contact-btn">
            Contact us
          </Link>
        </div>

        <div className="nu-icons">
          {data.map(({ id, title, link, image }) => (
            <div key={id} className="nu-icon--item nu-container">
              <div className="nu-image">
                <img
                  key={id}
                  src={withPrefix(image)}
                  alt={title}
                  loading="lazy"
                />
              </div>

              <span className="nu-title">{title}</span>

              <Link to={link} className="nu-read-btn">
                Read more
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SupportSection;
